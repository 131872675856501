import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    isLoggedIn = false;
    isLoading = false;

    form = new FormGroup({
        email: new FormControl(''),
        password: new FormControl(''),
    });

    constructor(
        private router: Router,
        private authService: AuthService,
        private tokenService: TokenService,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit(): void {
        if (this.tokenService.isLoggedIn()) {
            this.isLoggedIn = true;
            this.navigateToPackaging();
        }
    }

    onSubmit(): void {
        const { email, password } = this.form.controls;
        this.isLoading = true;

        this.authService.login(email.value, password.value).subscribe({
            next: () => {
                this.isLoggedIn = true;
                this.isLoading = false;
                this.navigateToPackaging();
                this.notificationsService.addSuccessMessage($localize`Successfully logged in!`);
            },
            error: () => {
                this.isLoading = false;
                this.notificationsService.addErrorMessage($localize`Login failed, please check your credentials.`);
            },
        });
    }

    private navigateToPackaging(): void {
        this.router.navigateByUrl('packaging/items');
    }
}

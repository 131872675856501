import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableComponent, DataTableValuePipe } from './components/datatable/datatable.component';
import { OverviewDrawerComponent } from './components/overview-drawer/overview-drawer.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { AssetUrlPipe, LevelIconPipe, LevelTranslationPipe, PackagingCharacteristicTypePipe } from 'src/app/shared/shared-pipes';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ConfirmationDialogComponent } from '@app/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { LevelIconComponent } from '@app/app/shared/components/level-icon/level-icon.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FlexLayoutModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        ConfirmationDialogComponent,
        DataTableComponent,
        EmptyStateComponent,
        LevelIconComponent,
        OverviewDrawerComponent,

        AssetUrlPipe,
        DataTableValuePipe,
        LevelIconPipe,
        LevelTranslationPipe,
        PackagingCharacteristicTypePipe,
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        FlexLayoutModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,

        ConfirmationDialogComponent,
        DataTableComponent,
        EmptyStateComponent,
        LevelIconComponent,
        OverviewDrawerComponent,

        AssetUrlPipe,
        DataTableValuePipe,
        LevelIconPipe,
        PackagingCharacteristicTypePipe,
    ],
    providers: [
        DatePipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class SharedModule { }

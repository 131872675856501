import { Injectable } from '@angular/core';
import { TokenResponseDto } from '../apis/verpakkingsregisterapi.client';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';

export const ACCESSTOKEN_STORAGE_KEY = 'access_token';

@Injectable({ providedIn: 'root' })
export class TokenService {

    accessToken$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    tokenExpiredSubject$: Subject<boolean> = new Subject<boolean>();

    private accessToken: string = undefined;
    private decodedAccessToken?: UserInfo;
    private timer$: Observable<0>;

    private jwtHelperService: JwtHelperService;

    constructor() {
        this.jwtHelperService = new JwtHelperService();
        const accessToken = localStorage.getItem(ACCESSTOKEN_STORAGE_KEY);
        if (!this.jwtHelperService.isTokenExpired(accessToken)) {
            this.accessToken = accessToken;
            this.monitorSession();
        }
    }

    getToken(): string {
        return this.accessToken;
    }

    getUserInfo(): UserInfo {
        if (!this.decodedAccessToken)
            this.decodedAccessToken = this.jwtHelperService.decodeToken(this.accessToken);

        return this.decodedAccessToken;
    }

    isLoggedIn(): boolean {
        if (this.accessToken && !this.jwtHelperService.isTokenExpired(this.accessToken))
            return true;

        return false;
    }

    saveToken(tokenResponse: TokenResponseDto): boolean {
        if (tokenResponse && !this.jwtHelperService.isTokenExpired(tokenResponse.accessToken)) {
            this.accessToken = tokenResponse.accessToken;
            this.decodedAccessToken = this.jwtHelperService.decodeToken(tokenResponse.accessToken);

            localStorage.setItem(ACCESSTOKEN_STORAGE_KEY, tokenResponse.accessToken);
            this.accessToken$.next(this.accessToken);
            return tokenResponse.accessToken !== undefined && tokenResponse.accessToken !== '';
        } else {
            this.accessToken = undefined;
            this.decodedAccessToken = undefined;

            localStorage.removeItem(ACCESSTOKEN_STORAGE_KEY);
            this.accessToken$.next(undefined);
            return false;
        }
    }

    monitorSession(): void {
        this.timer$ = timer(this.jwtHelperService.getTokenExpirationDate(this.accessToken));
        this.timer$.subscribe(() => {
            this.saveToken(undefined);
            this.tokenExpiredSubject$.next(true);
        });
    }
}

export interface UserInfo {
    name: string,
    givenname: string;
    surname: string,
    emailaddress: string,
    organisationId: number,
    role: string[],
}
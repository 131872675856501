import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../shared/token.service';

@Injectable()
export class TokenInterceptor {

    constructor(
        private tokenService: TokenService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.startsWith('http')) {
            const accessToken = this.tokenService.getToken();
            let headers = request.headers;

            if (accessToken)
                headers = headers.append('Authorization', `Bearer ${accessToken}`);

            request = request.clone({ headers });
        }

        return next.handle(request);
    }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import '@angular/localize/init';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './modules/login/login.component';
import { API_BASE_URL } from './apis/verpakkingsregisterapi.client';
import { TokenInterceptor } from './apis/token-interceptor';

import { environment } from 'src/environments/environment';

export function tokenGetter(): string {
    return localStorage.getItem('access_token');
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
    ],
    providers: [
        { provide: API_BASE_URL, useValue: environment.verpakkingsRegisterApiUrl },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

import { NgModule, inject } from '@angular/core';
import { CanActivateFn, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { TokenService } from 'src/app/shared/token.service';

const canActivate: CanActivateFn = () => {
    return inject(TokenService).isLoggedIn();
};

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' }, // Default route
    { path: 'login', component: LoginComponent },
    { path: 'packaging', loadChildren: () => import('./modules/packaging/packaging-routing.module').then((m) => m.PackagingRoutingModule), canActivate: [canActivate] },

    { path: '**', redirectTo: 'login' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }

import { Subject, Observable } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationMessage, NotificationsTypeEnum } from './notifications.model';

@Injectable({ providedIn: 'root' })
export class NotificationsService {

    private messages: NotificationMessage[] = [];
    private messagesSubject: Subject<NotificationMessage[]> = new Subject<NotificationMessage[]>();

    constructor(private snackbar: MatSnackBar, private zone: NgZone) { }

    getMessages(): Observable<NotificationMessage[]> {
        return this.messagesSubject.asObservable();
    }

    addInfoMessage(body: string, title?: string): void {
        this.addMessage(NotificationsTypeEnum.Info, body, title);
    }

    addSuccessMessage(body: string, title?: string): void {
        this.addMessage(NotificationsTypeEnum.Success, body, title);
    }

    addErrorMessage(body: string, title?: string): void {
        this.addMessage(NotificationsTypeEnum.Error, body, title);
    }

    private addMessage(type: NotificationsTypeEnum, body: string, title?: string): void {
        const message: NotificationMessage = { message: body, title, type };
        this.messages.push(message);

        const cssClass = message.type;

        this.zone.run(() => {
            this.snackbar
                .open(body, undefined, { duration: type === 'error' ? 5000 : 3000, verticalPosition: 'bottom', panelClass: `mat-snackbar-container-${cssClass}` })
                .afterDismissed().subscribe(() => this.dismissMessage(message));
        });
    }

    dismissMessage(message: NotificationMessage): void {
        const index = this.messages.indexOf(message);
        if (index !== -1) {
            const messages = [...this.messages];
            messages.splice(index, 1);
            this.messages = messages;
            this.messagesSubject.next([...this.messages]);
        }
    }
}

import { Injectable } from '@angular/core';
import { TokenResponseDto, VerpakkingsRegisterApiClient } from '../apis/verpakkingsregisterapi.client';
import { TokenService } from './token.service';
import { Observable, tap } from 'rxjs';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private tokenService: TokenService,
        private apiClient: VerpakkingsRegisterApiClient,
        private notificationsService: NotificationsService,
    ) { }

    login(email: string, password: string): Observable<TokenResponseDto> {
        return this.apiClient.login({ email, password }).pipe(tap((data) => {
            this.tokenService.saveToken(data);
            this.tokenService.monitorSession();
        }));
    }

    logout(): void {
        this.tokenService.saveToken(undefined);
        this.notificationsService.addSuccessMessage($localize`Successfully logged out!`);
    }
}

<div class="login-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title i18n="@@actions.login">Login</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                <mat-form-field>
                    <mat-label i18n="@@prop.email">Email</mat-label>
                    <input matInput placeholder="Email" formControlName="email" [autocomplete]="true">
                    <mat-hint>example@gmail.com</mat-hint>
                </mat-form-field>
                <mat-form-field>
                    <mat-label i18n="@@prop.password">Password</mat-label>
                    <input matInput type="password" i18n-placeholder="@@prop.password" placeholder="Password" formControlName="password">
                </mat-form-field>
                <button mat-raised-button class="btn-spinner" color="accent" type="submit" [disabled]="isLoading">
                    <span i18n="@@actions.login">Login</span>
                    <mat-icon *ngIf="isLoading"><mat-spinner diameter="18"></mat-spinner></mat-icon>
                </button>
            </form>
        </mat-card-content>
    </mat-card>
</div>
export class NotificationMessage {
    type: NotificationsTypeEnum;
    message: string;
    title: string;
    callback?: () => void;
}

export enum NotificationsTypeEnum {
    Info = 'info',
    Success = 'success',
    Error = 'error'
}